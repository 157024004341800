import { Route, Routes } from 'react-router-dom'
import { SiniestroScreen } from '../pages/view/SiniestroScreen'
import { TramiteLayout } from '../layout/TramitesLayout'
import { TramitesRouter } from './TramitesRoutes'

export const ViewRouter = () => {
  return (
    <Routes>
      <Route path="/siniestro/:siniestro" element={<SiniestroScreen />}></Route>
      <Route element={<TramiteLayout />}>
        <Route
          path="/siniestro/:siniestro/:tramite_id/*"
          element={<TramitesRouter />}
        ></Route>
      </Route>
    </Routes>
  )
}

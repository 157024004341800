import { Navigate, useParams } from "react-router-dom"
import { useGetProcesoActualQuery } from "../../../services/docs"

export const ProcesoScreen = () => {
  let { siniestro=0, tramite_id=0 } = useParams()
  const { data, isLoading } = useGetProcesoActualQuery({ siniestro, tramite: tramite_id })

  //       return <h1 className='flex w-screen bg-primary'>No tiene configurada ninguna vista</h1>
  if (isLoading) {
    return <h1>Cargando</h1>
  }
  // // if (error) {
  // //   if (error.status === 403) return <h1>{error.data.message}</h1>
  // // }
  if (!isLoading) {
    if (data) {
      if (data.path) {
        return (
          <Navigate to={`/view/siniestro/${siniestro}/${tramite_id}${data.path}`} />
        )
      }
      if (!data.path) {
        return (<h1>{data.message}</h1>)
      }
    }
  }
  return <></>
}

export const ItemEstatusDocumento = ({ label, color }) => {
  return (
    <div className="rounded-t border-solid px-2 flex flex-row gap-4 items-center justify-start h-12 border-b-4 relative overflow-hidden w-full cursor-pointer bg-light border-gray-ultra-light">
      <span
        className={`h-4 w-4 ${color}  bottom-0 right-0  rounded-full border-2 border-white `}
      ></span>
      <span className="text-xs">{label}</span>
    </div>
  )
}

import { useGetPendientesQuery } from "../../../services/docs"
import { useNavigate } from "react-router-dom"
import { DataTable } from "../../../components/EjemploTabla"

export const MisPendientesScreen = () => {
  const { data } = useGetPendientesQuery()
  const navigate = useNavigate()
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`)
  }
  const columns: any[] = [
    {
      accessorKey: 'fc_descripcion_producto',
      header: 'Producto',
    },
    {
      accessorKey: 'fc_nombre_etapa',
      header: 'Etapa',
    },
    {
      accessorKey: 't_fc_poliza',
      header: 'Póliza',
    },
    {
      accessorKey: 't_fc_siniestro',
      header: 'Siniestro',
    },
    {
      accessorKey: 't_id',
      header: 'Tramite',
    },
    {
      accessorKey: 'action',
      header: 'Action',
    },
  ]
  return (
    <div className="space-y-6">
      <p className="text-center text-2xl ">Mis Tramites Pendientes</p>
      {data && data.pendientes.length === 0 && (
        <div className="bg-danger my-4 py-2 text-center text-white rounded">No tienes pendientes</div>
      )}
      {data && data.pendientes.length > 0 && (
        <>
          <DataTable data={data.pendientes} columns={columns} action={verMas} />
        </>
      )}
    </div>
  )
}

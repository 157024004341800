import { useParams } from "react-router-dom"
import { useGetSemaforoQuery } from "../../../services/docs"
import { Spinner } from "../../../components/Spinner"
import { DataTable } from "../../../components/EjemploTabla"
import { elapsed } from "../../../hooks/elapsed"

const columns: any[] = [
  {
    accessorKey: 'fc_siniestro',
    header: 'Siniestro',
  },
  {
    accessorKey: 'fi_tramite_id',
    header: 'Tramite',
  },
  {
    accessorKey: 'fi_etapa',
    header: 'N Etapa',
  },
  {
    accessorKey: 'fc_nombre_etapa',
    header: 'Etapa',
  },
  {
    accessorKey: 'fd_fecha_inicial',
    header: 'Fecha Inicial',
  },
  {
    accessorKey: 'fi_minutos_transcurridos',
    header: 'tiempo-',
  },
  {
    accessorKey: 'fi_movimiento',
    header: 'Movimiento',
  },
  {
    accessorKey: 'fi_movimiento',
    header: 'Movimiento',
  },
]
export const TiemposScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams()
  const { data, isLoading } = useGetSemaforoQuery({ siniestro, tramite: tramite_id })
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <div className="grid grid-cols-1 gap-4 place-items-stretch">
      {data && data.semaforo && data.semaforo.length > 0 &&
        <>
          {elapsed(data.semaforo[0].fd_fecha_inicial)}

          <DataTable data={data.semaforo} columns={columns} action={() => { }} />
        </>
      }
    </div>

  )
}

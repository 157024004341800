import { Button, Input, Label, toast } from "@amex/amex-ui-react"
import { useForm } from "../../../hooks/useForm"
import { useNavigate, useParams } from "react-router-dom"
import { useGetCallcenterQuery, useSaveCallcenterMutation } from "../../../services/docs"

export const CallcenterScreen = () => {
  const { siniestro, tramite_id = 0 } = useParams()
  const { data: callcenter } = useGetCallcenterQuery({ siniestro, tramite: tramite_id });
  const [saveCallcenter] = useSaveCallcenterMutation();
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleTexareChange, handleSelectChange } = useForm({
    fc_llamada_id: '',
    fi_calificacion_llamada: '',
    fc_comentarios_llamada: '',
    tramite_id: Number(tramite_id),
    siniestro: siniestro,
  })
  const handleAction = async () => {
    console.log(formulario)
    const resp = await saveCallcenter({
      ...formulario,
      tramite_id: +tramite_id,
      fi_calificacion_llamada: +formulario.fi_calificacion_llamada,
    }).unwrap().catch((err: any) => {
      if (err.status === 400) {
        if (err.data.message) {
          if (Array.isArray(err.data.message)) {
            err.data.message.map((m: any) => {
              toast({
                description: m,
                title: 'Error'
              })
            })
          }
        }
      }
    })
    if (resp) {
      toast({
        description: resp.message,
        title: 'Etapa'
      })
      navigate('/view/siniestro/' + siniestro + '/' + tramite_id + '/processo');
    }
  }
  return (
    <div className="flex w-full">
      <div className="form">
        <div className="flex flex-col my-5">
          <Label htmlFor="">Id de llamada:</Label>
          <Input type="text"
            name="fc_llamada_id"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex-col my-5">
          <Label htmlFor="">Calificación:</Label>
          <select
            className='flex items-center justify-between h-12 w-full rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50'
            name="fi_calificacion_llamada"
            onChange={handleSelectChange}
          >
            <option value={0}>Calificación</option>
            {callcenter && callcenter.calificaciones && callcenter.calificaciones.map((r: any) => {
              return <option key={r.id} value={Number(r.id)}>{r.fc_calificacion}</option>
            })
            }
          </select>
        </div>
        <div className="flex flex-col my-5">
          <Label htmlFor="">Comentario:</Label>
          <textarea
            name="fc_comentarios_llamada"
            onChange={handleTexareChange}
            className='flex items-center justify-between h-12 w-full rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50'
          >
          </textarea>
        </div>
        <div className="flex flex-col ">
          <Button onClick={handleAction}>Guardar Llamada</Button>
        </div>
      </div>
    </div>
  )
}


export const elapsed = (fecha: string) => {
  let seconds = 0;
  let counter = 0;
  let tiempo = '';
  const intervals = {
    'año': 31536000,
    'mes': 2592000,
    'semana': 604800,
    'dia': 86400,
    'hora': 3600,
    'minuto': 60,
    'segundo': 1
  };
  seconds = Math.floor((+new Date() - +new Date(fecha)) / 1000);
  if (seconds < 29) {
    return 'Ahora';
  }
  for (const i in intervals) {
    counter = Math.floor(seconds / intervals[i]);
    if (counter > 0) {
      seconds = seconds - (intervals[i] * counter)
      if (counter === 1) {
        tiempo += counter + ' ' + i + ' ';
      } else {
        tiempo += counter + ' ' + i + 's ';
      }
    }
  }
  return tiempo;

}
export const elapsedMinutos = (minutos: number) => {
  let seconds = 0;
  let counter = 0;
  let tiempo = '';
  const intervals = {
    'año': 31536000,
    'mes': 2592000,
    'semana': 604800,
    'dia': 86400,
    'hora': 3600,
    'minuto': 60,
    'segundo': 1
  };
  seconds = Math.floor(minutos * 60);
  if (seconds < 29) {
    return 'Ahora';
  }
  for (const i in intervals) {
    counter = Math.floor(seconds / intervals[i]);
    if (counter > 0) {
      seconds = seconds - (intervals[i] * counter)
      if (counter === 1) {
        tiempo += counter + ' ' + i + ' ';
      } else {
        tiempo += counter + ' ' + i + 's ';
      }
    }
  }
  return tiempo;
}

import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { Button } from "@amex/amex-ui-react"

export const TramiteLayout = () => {
  console.log('paso tramite layout')
  const navigate = useNavigate()
  let { siniestro, tramite_id } = useParams()
  const regresar = () => {
    navigate(`/view/siniestro/${siniestro}`)
  }
  return (
    <>
      <h1 className="text-2xl">
        Tramite {siniestro}-{tramite_id}</h1>
      <Button onClick={regresar}>Ver Siniestro</Button>
      <div className="space-y-5">
        <div className="border-b border-b-gray-200">
          <ul className="-mb-px flex items-center gap-4 text-sm font-medium">
            <li className="flex-1">
              <Link
                to={`/view/siniestro/${siniestro}/${tramite_id}/`}
                className="relative flex items-center justify-center gap-2 px-1 py-3 text-primary after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full after:bg-primary hover:text-primary"
              >
                Proceso Actual
              </Link>
            </li>
            <li className="flex-1">
              <Link
                to={`/view/siniestro/${siniestro}/${tramite_id}/expediente`}
                className="relative flex items-center justify-center gap-2 px-1 py-3  after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full  hover:text-primary"
              >
                Expediente
              </Link>
            </li>
            <li className="flex-1">
              <Link
                to={`/view/siniestro/${siniestro}/${tramite_id}/contacto-cliente`}
                className="relative flex items-center justify-center gap-2 px-1 py-3 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full hover:text-primary"
              >
                Contacto Cliente
              </Link>
            </li>
            <li className="flex-1">
              <Link
                to={`/view/siniestro/${siniestro}/${tramite_id}/tiempos`}
                className="relative flex items-center justify-center gap-2 px-1 py-3 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full hover:text-primary"
              >
                Tiempos
              </Link>
            </li>
            <li className="flex-1">
              <Link
                to={`/view/siniestro/${siniestro}/${tramite_id}/dictamenes`}
                className="relative flex items-center justify-center gap-2 px-1 py-3 after:absolute after:left-0 after:bottom-0 after:h-0.5 after:w-full hover:text-primary"
              >
                Dictamentes
              </Link>
            </li>
          </ul>
        </div>
        <Outlet />
      </div>
    </>
  )
}

import { Toaster } from '@amex/amex-ui-react'
import { AnimatePresence, motion, MotionConfig } from 'framer-motion'
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { customTransition } from '../lib/animations'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useValidarSesionQuery } from '../services/docs'
import Menu from '../components/ui/Menu'

export const PrivateLayout = () => {
  const token = localStorage.getItem('app_token') || ''
  if (!token) {
    return <Navigate to="/login" replace />
  }
  const path = useLocation()
  const { data } = useValidarSesionQuery()
  const menusItems: any[] = [
    {
      estado: 'activo',
      href: '/home/mis-pendientes',
      icono: 'campana',
      label: 'Mis Pendientes',
    },
    {
      estado: 'activo',
      href: '/home/buscar',
      icono: 'circulo',
      label: 'Buscar Tramite',
    },
  ]
  return (
    <MotionConfig transition={customTransition}>
      <div className="relative min-h-screen max-h-screen h-screen w-screen min-w-screen max-w-screen overflow-clip shadow">
        <motion.div
          layout
          className="flex min-h-full max-h-full bg-gray-ultra-light overflow-y-auto"
        >

          {data.user && <Menu items={menusItems} user={data.user} />}
          <AnimatePresence mode="popLayout">
            <motion.div
              key={path.pathname}
              layoutId={path.pathname}
              initial={{ opacity: 0, visibility: 'hidden' }}
              animate={{
                opacity: 1,
                transition: { delay: 0.25 },
                visibility: 'visible',
              }}
              exit={{ opacity: 0, visibility: 'hidden' }}
              transition={{
                duration: 0.5,
              }}
              className="relative w-full h-full p-6 xl:p-12"
            >
              <Outlet />
              {/* <div className="mx-auto"> */}
              {/*   {data.user && */}
              {/*     <div className="bg-primary w-screen text-white text-center"> hola, {data.user.fc_nombres},  {data.user.fc_perfil} (<span onClick={cerrarSesion}>Salir</span>)</div> */}
              {/*   } */}
              {/*   <div className="container mx-auto"> */}
              {/*     <Outlet /> */}
              {/*   </div> */}
              {/* </div> */}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
    </MotionConfig>
  )
}

import { Button, Input, Label, toast } from "@amex/amex-ui-react"
import { useForm } from "../../../hooks/useForm"
import { useNavigate, useParams } from "react-router-dom"
import { useAuditoriaMutation } from "../../../services/docs"

export const AuditoriaScreen = () => {
  const { siniestro, tramite_id = 0 } = useParams()
  const [auditoria] = useAuditoriaMutation();
  const navigate = useNavigate();
  const { formulario, handleSelectChange,handleTexareChange } = useForm({
    fi_resolucion_auditoria: '',
    fc_comentarios_auditoria: '',
    tramite_id: Number(tramite_id),
    siniestro: siniestro,
  })
  const handleAction = async () => {
    const resp = await auditoria({
      ...formulario,
      tramite_id: +tramite_id,
      fi_resolucion_auditoria: +formulario.fi_resolucion_auditoria,
    }).unwrap().catch((err: any) => {
      if (err.status === 400) {
        if (err.data.message) {
          if (Array.isArray(err.data.message)) {
            err.data.message.map((m: any) => {
              toast({
                description: m,
                title: 'Error'
              })
            })
          }
        }
      }
    })
    if (resp) {
      toast({
        description: resp.message,
        title: 'Etapa'
      })
      navigate('/view/siniestro/' + siniestro + '/' + tramite_id + '/');
    }
  }
  return (
    <>
      <div className="flex w-full h-10"></div>
      <div className='grid grid-cols-3 gap-6 place-items-stretch'>
        <div className=''>
          <div className="flex flex-col px-6 py-2">
            <Label htmlFor="">Respuesta:</Label>
            <select
              className='flex items-center justify-between h-12 w-full rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50'
              name="fi_resolucion_auditoria"
              onChange={handleSelectChange}
            >
              <option value="">Selecciona Respuesta</option>
              <option value="1">Dictamen Correcto</option>
              <option value="2">Dictamen Incorrecto</option>
            </select>
          </div>
          <div className="flex flex-col px-6 py-2">
            <Label htmlFor="">Comentario:</Label>
            <textarea
              name="fc_comentarios_auditoria"
              onChange={handleTexareChange}
              className='flex items-center justify-between h-12 w-full rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50'
            >
            </textarea>
          </div>
          <div className="flex flex-col px-6 py-2">
            <Button onClick={handleAction}>Enviar tramite</Button>
          </div>
        </div>
      </div>
    </>
  )
}

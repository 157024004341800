import { ChangeEvent, useState } from 'react'

export const useForm = <T extends Object> (initialState: T ) => {
  const [formulario, setFormulario] = useState(initialState)

  // const reset = () => {
  //   setValues(initialState)
  // }

  const handleInputChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleSelectChange = ({target}: ChangeEvent<HTMLSelectElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleTexareChange = ({target}: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value} = target
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  const handleChange = (name:string,value:string) => {
    setFormulario({
      ...formulario,
      [name]: value,
    })
  }
  return {formulario, handleInputChange,handleSelectChange,handleTexareChange,handleChange}
}

import { Input, Label, Button } from '@amex/amex-ui-react'
import { useForm } from '../../hooks/useForm';
import { useLazyValidarSesionQuery, useLoginMutation, useValidarSesionQuery } from '../../services/docs';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/ui/Logo';

export const LoginScreen = () => {
  const navigate = useNavigate();
  const [loginSend, { isLoading, error, isSuccess, reset }] = useLoginMutation()
  const [validar] = useLazyValidarSesionQuery()
  const { formulario, handleInputChange } = useForm({
    password: '',
    username: '',
  })
  const { password, username } = formulario
  const handleLogin = async (e: any) => {
    e.preventDefault()
    const resp = await loginSend(formulario).unwrap()
    localStorage.setItem('app_token', resp.token)
    validar()
    navigate('/home/inicio', { replace: true })
  }
  return (

    <div className='relative min-h-screen max-h-screen h-screen w-screen min-w-screen max-w-screen overflow-clip'>
      <div className="relative grid lg:grid-cols-2 min-h-full">
        <div className="w-full h-full lg:hidden">
          <img
            alt=""
            src="/images/logjn-amex-bg.jpg"
            width={2410}
            height={2779}
            className="!h-full !w-auto aspect-auto absolute lg:relative inset-0"
          />
        </div>

        <div
          className="w-full h-full hidden lg:block"
          style={{
            clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
          }}
        >
          <img
            alt=""
            src="/images/logjn-amex-bg.jpg"
            width={2410}
            height={2779}
            className="!h-full !w-auto aspect-auto absolute lg:relative inset-0"
          />
        </div>

        <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full max-w-md">
          <div className="card flex flex-col items-center justify-center space-y-4">
            <Logo />
            {/* <FormLogin /> */}
            <form className="w-full space-y-6" action="#" method="POST">
              <div>
                <Label>Usuario</Label>
                <Input
                  name="username"
                  value={username}
                  onChange={handleInputChange}
                  type="text"
                />
              </div>
              <div>
                <Label>Contraseña</Label>
                <Input
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  type="password"
                />
              </div>
              <div>
                <Button size="lg" className="w-full" onClick={handleLogin} >Iniciar Sesión</Button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

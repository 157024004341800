import { useParams } from "react-router-dom"
import { useGetDictamenesQuery } from "../../../services/docs"
import { Spinner } from "../../../components/Spinner"
import { ItemDictamen } from "../../../components/ItemDictamen"

export const DictamenesScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams()
  const { data, isLoading } = useGetDictamenesQuery({ siniestro, tramite: tramite_id })
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <div className="grid grid-cols-1 gap-4 place-items-stretch">
      {data && data.dictamenes && data.dictamenes.length > 0 && data.dictamenes.map((dic: any) => {
        return (
          <div className="card flex flex-col space-y-5" key={dic.id}>
            <div className='grid grid-cols-4 gap-4 place-items-stretch'>
              <ItemDictamen titulo={'Id de Dictamen'} subtitulo={dic.id} />
              <ItemDictamen titulo={'Respuesta Dictamen'} subtitulo={dic.fc_respuesta_aseguradora} />
              <ItemDictamen titulo={'Comentario Dictamen'} subtitulo={dic.fc_comentarios_dictamen} />
              <ItemDictamen titulo={'Siniestro Aseguradora'} subtitulo={dic.fc_siniestro_aseguradora} />
              <ItemDictamen titulo={'Fecha Dictamen'} subtitulo={dic.fd_fecha_dictamen} />
              <ItemDictamen titulo={'Resolución Auditoria'} subtitulo={dic.fc_resolucion_auditoria} />
              <ItemDictamen titulo={'Comentario Auditoria'} subtitulo={dic.fc_comentarios_auditoria} />
              <ItemDictamen titulo={'Fecha Auditoria'} subtitulo={dic.fd_fecha_auditoria} />
              <div className="">
                <p className="block text-base ">
                  <a className='text-primary bg-primary text-white focus-visible:ring-primary-ultra-light px-4 py-2 text-sm' href={dic.fc_url_carta} target='_blank'>Ver carta dictamen</a>
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

import { Button, Input, Label, toast } from "@amex/amex-ui-react"
import { useForm } from "../../../hooks/useForm"
import { useNavigate, useParams } from "react-router-dom"
import { useGetFinalizadoQuery } from "../../../services/docs"
import { Spinner } from "../../../components/Spinner"

export const FinalizadoScreen = () => {
  const { siniestro, tramite_id = 0 } = useParams()
  const { data, isLoading } = useGetFinalizadoQuery({ siniestro, tramite: tramite_id });
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <div className=''>
      <div className="card grid gap-4 bg-white">
        {data && data.finalizado &&
          <>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 text-left">
              <div className="space-y-6">
                <p className="text-xl @lg:text-xl @xl:text-2xl">
                  El tramite esta Finalizado se dictamino como:
                </p>
                <p className="text-3xl @lg:text-3xl @xl:text-3xl">
                  {data.finalizado[0].d_fc_respuesta_aseguradora}
                </p>
                {data.finalizado[0].d_fi_respuesta_aseguradora === 1 && <span className="block text-base">
                  Monto pagado: {data.finalizado[0].t_fn_monto_reclamado}
                </span>
                }
                <p className="block text-base">
                  Comentarios Dictamen:
                </p>
                <p className="block text-base">
                  {data.finalizado[0].d_fc_comentarios_dictamen}
                </p>
              </div>
              <div className="space-y-6 ">
                <p className="block text-base text-center mt-5">
                  <a className='text-primary bg-primary text-white focus-visible:ring-primary-ultra-light px-4 py-2 text-sm' href={data.finalizado[0].d_fc_url_carta} target='_blank'>Ver carta dictamen</a>
                </p>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )

}

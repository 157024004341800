import { Route, Routes } from 'react-router-dom'

import { BuscarScreen } from '../pages/private/200/BuscarScreen'
import { HomeScreen } from '../pages/private/200/HomeScreen'
import { MisPendientesScreen } from '../pages/private/200/MisPendientes'

export const PrivadaRouter = () => {
  return (
    <Routes>
      <Route path="/inicio" element={<HomeScreen />}></Route>
      <Route path="/buscar" element={<BuscarScreen />}></Route>
      <Route path="/mis-pendientes" element={<MisPendientesScreen />}></Route>
      <Route path="/*" element={<HomeScreen />}></Route>
    </Routes>
  )
}

export const ItemComponent = (prop: {titulo:string, subtitulo:string}) => {
  return (
    <div className="ml-5 flex items-center  gap-x-3 my-3">
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-400 truncate">{prop.titulo}</p>
        <p className="text-sm text-dark truncate">{prop.subtitulo}</p>
      </div>
    </div>
  )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { store } from './store'
import { Toaster } from '@amex/amex-ui-react'

const root = ReactDOM.createRoot(document.getElementById('root')!)

if (process.env.NODE_ENV === 'development') {
  root.render(
    <Provider store={store}>
      <Toaster />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
} else {
  root.render(
    <Provider store={store}>
      <Toaster />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
}

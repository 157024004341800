import { Button, toast } from "@amex/amex-ui-react";
import { useCalificarDocumentoMutation, useCargaDocumentoMutation, useLazyGetDocsListQuery } from "../services/docs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./Spinner";
import { useEffect, useState } from "react";

export const ItemDocumentoValidar = ({
  doc,
  i,
  valor,
  siniestro,
  tramite_id, }) => {
  const [guardaCalificacion, { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset }] = useCalificarDocumentoMutation()
  const [tigger] = useLazyGetDocsListQuery()
  const [cal, setCal] = useState(0)
  const onChange = (e: any) => {
    valor[i].comentario = e.target.value
  }
  const onClickRadio = (e: any) => {
    valor[i].respuesta = e.target.value
  }
  const rectificar = (indice) =>{
    console.log(valor[i].calificado)
    setCal(1)
  }
  const guardarCalificacion = async () => {
    if (!valor[i].respuesta) {
      toast({
        description: 'Debes de agregar una calificación',
        title: 'Error al guardar respuesta',
      })
      return
    }
    if (valor[i].respuesta === '2' && !valor[i].comentario) {
      toast({
        description: 'Debes de agregar un comentario',
        title: 'Error al guardar respuesta',
      })
      return
    }
    const resp = await guardaCalificacion({
      siniestro,
      tramite_id: +tramite_id,
      documento_id: doc.id,
      calificacion_id: Number(valor[i].respuesta),
      comentario: valor[i].comentario
    }).unwrap()
    setCal(0)
    toast({
      description: resp.message,
      title: 'Documento',
    })
    tigger({ siniestro, tramite: tramite_id })

  }
  let color = 'bg-gray'
  let urlDoc = ''

  if (doc.documento) {
    if (doc.documento.length > 0) {
      urlDoc = doc.documento[0].fc_ruta_documento;
      valor[i].calificado = doc.documento[0].fi_calificado;

      switch (doc.documento[0].fi_rechazo_digital) {
        case 0:
          color = 'bg-green-400'
          break
        case 1:
          color = 'bg-primary'
          break
        case 2:
          color = 'bg-yellow-400'
          break
        default:
          color = 'bg-gray'
          break
      }
    }
  }

  return (
    <div key={doc.id} className="bg-white w-full flex-row items-center p-2 rounded-xl shadow border">
      <div className={`w-12 ${color} h-2 rounded-xl overflow-hidden`}></div>
      <div className="relative flex items-center space-x-1">
        {isLoadingCarga && <Spinner />}
        {!isLoadingCarga &&
          <>
            {/* <input onChange={(e) => onChange(e, doc, i)} className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file" /> */}
          </>
        }
      </div>
      <div className="flex-grow p-3">
        <div className="flex w-full justify-end">
          {urlDoc && <a className='mx-4 p-1 text-white text-right text-sm bg-primary-light rounded' href={urlDoc} target='_blank' >Ver Documento</a>}
        </div>
        <div className="font-semibold text-gray-700">
          {doc.fc_nombre_documento}
        </div>
        <div className="text-sm text-gray-500">
          {doc.fc_descripcion_documento}
        </div>

        { valor[i].calificado === 1 && cal===0 &&
          <div>
            <span>¿El documento es correcto ?</span>
            <div>{doc.documento[0].fi_rechazo_digital === 1? 'Si':'No'}</div>
            <div>
              <label htmlFor="comentarios">Comentarios:</label>
              <div>{doc.documento[0].fc_comentarios_rechazo }</div>
              <Button className='my-5 bg-primary-ultra-light' onClick={() => rectificar(i)}>Rectificar</Button>
            </div>
          </div>
        }
        {(!valor[i].calificado || cal===1) &&
          <div>
            <span>El documento esta correcto?</span>
            <div><input onClick={onClickRadio} type="radio" value='1' name='respuesta' />Si</div>
            <div><input onClick={onClickRadio} type="radio" value='2' name='respuesta' />No</div>
            <div>
              <label htmlFor="comentarios">Comentarios:</label>
              <textarea onChange={onChange} name="comentarios" id="" cols={40} rows={2}></textarea>
              <Button className='my-5' onClick={() => guardarCalificacion()}>Guardar Calificación</Button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

import * as React from "react";
import { Icon, IIconProps } from "./Icon";
import { Logo } from "./Logo";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverContent, PopoverTrigger } from "@amex/amex-ui-react";
import * as Portal from "@radix-ui/react-portal";
import { Link, useLocation, useNavigate } from "react-router-dom";

// ---
// Menú que contiene el menú de escritorio y el menú de móvil
// ---
export interface IMenuProps {
  items: IItemMenuProps[]
  user: any
}

const Menu = ({ items, user }: IMenuProps): JSX.Element => {
  return (
    <div className="sticky top-0 left-0 flex flex-row gap-4 items-start justify-start bg-light w-min min-h-full shadow-right">
      <div className="relative h-full w-full">
        <MenuEscritorio items={items} user={user} />
        <MenuMovil items={items} />
      </div>
    </div>
  )
}

// ---
// End of Menú que contiene el menú de escritorio y el menú de móvil
// ---

// ---
// Menú de escritorio
// ---

export interface IMenuEscritorioProps {
  items: IItemMenuProps[]
  user: any
}

export const MenuEscritorio = ({
  items,
  user,
}: IMenuEscritorioProps): JSX.Element => {
  const navigate = useNavigate()
  const cerrarSesion = () => {
    localStorage.setItem('app_token', '')
    navigate('/login')
  }
  return (
    <div
      className={
        "pt-6 pr-0 pb-0 pl-0 flex-col gap-6 items-start justify-start shrink-0 w-[280px] h-full relative hidden md:flex"
      }
    >
      <Link
        to={'/'}
        className={
          "pt-6 pr-4 pb-6 pl-4 flex flex-col gap-2 items-center justify-start self-stretch shrink-0 relative"
        }
      >
        <Logo />
      </Link>

      <div
        className={
          "pt-0 pr-4 pb-0 pl-4 flex flex-row gap-2 items-start justify-start self-stretch shrink-0 relative"
        }
      >
        {/* <div className={"w-full text-dark text-left"}><span className="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-primary dark:text-white">{user.fc_perfil}</span></div> */}
        <div className={"text-dark text-left flex w-full flex flex-col"}>
          <span className="w-full mb-2">
            <span className="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-primary dark:text-white">{user.fc_perfil}</span>
            <span onClick={cerrarSesion} className="bg-gray-dark text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-dark dark:text-white">Salir</span>
          </span>
          <span className="w-full">Hola, {user.fc_nombres}</span>
        </div>
      </div>

      <motion.div className={"w-full space-y-0"}>
        {items.map((item, i) => (
          <ItemMenu key={i} {...item} />
        ))}
      </motion.div>
    </div>
  )
}

// ---
// End of Menú de escritorio
// ---

// ---
// Menú de móvil
// ---

export interface IMenuMovilProps {
  items: IItemMenuProps[];
}
export const MenuMovil = ({ items }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Portal.Root>
      <div className={"fixed bottom-4 right-4 md:hidden !z-[999]"}>
        <div className={"relative"}>
          <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
            <PopoverTrigger asChild>
              {/* Icono de menú */}
              <motion.div
                className={
                  "h-12 w-12 bg-light shadow-xl flex items-center justify-center rounded-full overflow-hidden cursor-pointer"
                }
              >
                <Icon variante={isOpen ? "x" : "menu"} color="dark" size={32} />
              </motion.div>
              {/* End of Icono de menú */}
            </PopoverTrigger>
            <PopoverContent className="p-0 mr-6">
              <motion.div className={"w-full space-y-0"}>
                {items?.map((item, i) => (
                  <ItemMenu key={i} {...item} />
                ))}
              </motion.div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </Portal.Root>
  )
}

// ---
// End of Menú de móvil
// ---

// ---
// Item de menú
// ---

export interface IItemMenuProps {
  estado?: "activo" | "inactivo" | "deshabilitado";
  label: string;
  icono: IIconProps["variante"];
  onClick: () => void;
  href: string;
}

const ItemMenu = ({
  estado = "activo",
  label,
  icono,
  href,
}: IItemMenuProps): JSX.Element => {
  const height = 60
  const location = useLocation()
  return (
    <div className={"relative"}>
      <Link
        to={href || '#'}
        className={
          "flex flex-row gap-4 items-center justify-start self-stretch shrink-0 relative overflow-hidden"
        }
      >
        <motion.div
          className={`px-4 flex flex-row gap-4 items-center justify-start flex-1 relative`}
          initial={{ height }}
          animate={{
            height,
            color: estado === "activo" ? "primary" : "dark",
          }}
        >
          <Icon variante={icono} />

          <div className={`text-left relative flex-1`}>{label}</div>
        </motion.div>
      </Link>
      {/*  Indicador de activo */}
      <AnimatePresence mode="sync">
        {href === location.pathname && (
          <motion.div
            layoutId="menuItemActivoIndicador"
            initial={{ height }}
            className={`absolute inset-0 w-full border-l-4 md:border-l-0 md:border-r-4 z-[0] border-primary-light bg-primary-light/20`}
          />
        )}
      </AnimatePresence>
      {/*  End of Indicador de activo */}
    </div>
  )
}

// ---
// End of Item de menú
// ---

export default Menu

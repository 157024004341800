import { Routes, Route } from "react-router-dom"
import { PublicLayout } from '../layout/publicLayout'
import { LoginAuth } from "../hooks/LoginAuth"
import { LoginScreen } from "../pages/public/loginScreen"
import { PrivateLayout } from "../layout/privateLayout"
import { RequiereAuth } from "../hooks/RequiereAuth"
import { PrivadaRouter } from "./PrivateRoutes"
import { ViewRouter } from "./ViewRoutes"

export const IndexRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path="/*"
          element={
            <LoginAuth>
              <LoginScreen />
            </LoginAuth>
          }
        ></Route>
      </Route>
      <Route element={<PrivateLayout />}>
        <Route
          path="/home/*"
          element={
            <RequiereAuth>
              <PrivadaRouter />
            </RequiereAuth>
          }
        ></Route>
      </Route>
      <Route element={<PrivateLayout />}>
        <Route
          path="/view/*"
          element={
            <RequiereAuth>
              <ViewRouter />
            </RequiereAuth>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

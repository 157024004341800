import { Label, Input, Button, toast } from "@amex/amex-ui-react"
import { Spinner } from "../../../components/Spinner"
import { useBuscarTramiteMutation, useGetBusquedaQuery } from "../../../services/docs"
import { useForm } from "../../../hooks/useForm"
import { DataTable } from "../../../components/EjemploTabla"
import { useNavigate } from "react-router-dom"

const columns: any[] = [
  {
    accessorKey: 'fc_descripcion_producto',
    header: 'Producto',
  },
  {
    accessorKey: 'fc_nombre_etapa',
    header: 'Etapa',
  },
  {
    accessorKey: 't_fc_poliza',
    header: 'Póliza',
  },
  {
    accessorKey: 't_fc_siniestro',
    header: 'Siniestro',
  },
  {
    accessorKey: 't_id',
    header: 'Tramite',
  },
  {
    accessorKey: 'action',
    header: 'Action',
  },
]
export const BuscarScreen = () => {
  const { data, isLoading } = useGetBusquedaQuery()
  const navigate = useNavigate();
  const { formulario, handleInputChange, handleSelectChange } = useForm({
    fi_busqueda_id: '',
    fc_valor: '',
  })
  const verMas = (p: any) => {
    navigate(`/view/siniestro/${p.t_fc_siniestro}/${p.t_id}/proceso`)
  }
  const [buscarTramiteAction, { data: siniestros, error }] = useBuscarTramiteMutation();
  const buscarTramite = async () => {
    console.log(formulario)
    if (+formulario.fi_busqueda_id === 0) {
      toast({
        description: 'Debe seleccionar un tipo de búsqueda',
        title: 'Error'
      })
      return;
    }
    if (formulario.fc_valor === '') {
      toast({
        description: 'Debes de agregar un valor de busqueda',
        title: 'Error'
      })
      return;
    }
    await buscarTramiteAction({
      ...formulario,
      fi_busqueda_id: +formulario.fi_busqueda_id,
    }).unwrap()
  }
  if (isLoading) {
    return <div className='flex w-full justify-center'><Spinner /></div>
  }
  return (
    <>
      <div className="grid grid-cols-3 gap-4 place-items-stretch">
        <div>
          <div className="flex flex-col px-6 py-2">
            <Label htmlFor="">Tipo Búsqueda:</Label>
            <select
              className='flex items-center justify-between h-12 w-full rounded border border-gray-light bg-gray-ultra-light px-3 py-2 text-sm ring-primary-ultra-light file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:opacity-50'
              name="fi_busqueda_id"
              onChange={handleSelectChange}
            >
              <option value="">Selecciona</option>
              {data && data.busqueda && data.busqueda.map((b: any) => {
                return (<option key={b.id} value={b.id}>{b.fc_nombre_busqueda}</option>)
              })}
            </select>
          </div>
        </div>
        <div>
          <div className="flex flex-col px-6 py-2">
            <Label htmlFor="">Valor a buscar:</Label>
            <Input type="text"
              name="fc_valor"
              onChange={handleInputChange}
            />
          </div>

        </div>
        <div>
          <div className="flex flex-col px-6 py-2 mt-6">
            <Button onClick={buscarTramite}>Buscar</Button>
          </div>
        </div>
      </div >
      <div className="grid grid-cols-1 gap-4 place-items-stretch mt-10">
        {siniestros && siniestros.busquedas && siniestros.busquedas.length > 0 &&
          <DataTable data={siniestros.busquedas} columns={columns} action={verMas} />
        }
        {siniestros && siniestros.busquedas && siniestros.busquedas.length === 0 &&
          <div className="bg-danger my-4 py-2 text-center text-white rounded">No se encontraron datos</div>
        }
      </div>
    </>
  )
}

import { Button, toast } from "@amex/amex-ui-react";
import { useCargaDocumentoMutation, useLazyGetDocsListQuery } from "../services/docs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./Spinner";

export const ItemDocumentoCarga = ({
  doc,
  i,
  valor,
  siniestro,
  tramite_id, }) => {
  const [cargaSend, { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset }] = useCargaDocumentoMutation()
  const [tigger] = useLazyGetDocsListQuery()
  const onChange = (e: any, doc: any, i: number) => {
    const files = e.target.files
    const file = files[0]
    getBase64(file, doc, i)
  }
  const getBase64 = (file: any, doc: any, i: number) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onLoad(reader.result, doc, i)
    }
  }
  const onLoad = (fileString: any, doc: any, i: number) => {
    valor[i].imagen = fileString
  }
  const cargarDocumento = async () => {
    if (!valor[i].imagen) {
      toast({
        description: 'Debes de cargar un documento',
        title: 'Error de carga',
      })
      return
    }
    const resp = await cargaSend({
      documento: valor[i].imagen,
      documento_id: doc.id,
      siniestro: siniestro,
      tramite_id: Number(tramite_id),
    }).unwrap()
    toast({
      description: resp.message,
      title: 'Docuemnto',
    })
    tigger({ siniestro, tramite: tramite_id })
    // navigate(`/view/siniestro/${siniestro}/${tramite_id}/`)
  }
  let color = 'bg-gray'
  let urlDoc = ''

  if (doc.documento) {
    if (doc.documento.length > 0) {
      urlDoc = doc.documento[0].fc_ruta_documento;
      switch (doc.documento[0].fi_rechazo_digital) {
        case 0:
          color = 'bg-green-400'
          break
        default:
          color = 'bg-gray'
          break
        case 1:
          color = 'bg-primary'
          break
        case 2:
          color = 'bg-yellow-400'
          break
      }
    }
  }
  return (
    <div key={doc.id} className="bg-white w-full flex-row items-center p-2 rounded-xl shadow border">
      <div className={`w-1/4 ${color} h-2 rounded-xl overflow-hidden`}></div>
      <div className="relative flex-row items-center space-x-1">
        {isLoadingCarga && <Spinner />}
        {(!isLoadingCarga && doc.documento && doc.documento.length > 0 && doc.documento[0].fi_rechazo_digital !== 1 ||
          !isLoadingCarga && !doc.documento ||
          !isLoadingCarga && doc.documento.length === 0)
        &&
          <div className='flex w-full justify-center'>
            <input onChange={(e) => onChange(e, doc, i)} className="my-4 w-1/2 mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file" />
          </div>
        }
        {(!isLoadingCarga && doc.documento && doc.documento.length > 0 && doc.documento[0].fi_rechazo_digital === 2) &&
          <div className='flex w-full justify-center'>
            <p>{doc.documento[0].fc_comentarios_rechazo}</p>
          </div>
        }
      </div>
      <div className="flex-grow p-3">
        <div className="font-semibold text-gray-700">
          {doc.fc_nombre_documento}
        </div>
        <div className="text-sm text-gray-500">
          {doc.fc_descripcion_documento}
        </div>

        {(!isLoadingCarga && doc.documento && doc.documento.length > 0 && doc.documento[0].fi_rechazo_digital !== 1 ||
          !isLoadingCarga && !doc.documento ||
          !isLoadingCarga && doc.documento.length === 0
         ) &&
          <Button className='my-5' onClick={() => cargarDocumento()}>Cargar Documento</Button>
        }
        {urlDoc && <a className='mt-3 p-1 text-white text-sm bg-gray-500 rounded' href={urlDoc} target='_blank' >Ver Documento</a>}
      </div>
    </div>
  )
}

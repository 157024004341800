import { cubicBezier } from "framer-motion";

export const easing = cubicBezier(0.645, 0.045, 0.355, 1);

export const customTransition = {
  duration: 0.6,
  ease: easing,
};

export const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

export const stagger = {
  animate: {
    transition: {
      ...customTransition,
      staggerChildren: 0.1,
    },
  },
};

export const imageReveal = {
  initial: {
    y: "100%",
  },
  animate: {
    y: 0,
    transition: {
      ...customTransition,
      duration: 1,
    },
  },
};

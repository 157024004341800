import React from 'react'
import { IndexRoutes } from './routes/IndexRoutes'
import { useValidarSesionQuery } from './services/docs'
import { ManejoErrores } from './hooks/Errores'

const App: React.FC = () => {
  const { isLoading, error } = useValidarSesionQuery()
  if (isLoading) {
    return (
      <div>
        <span className="spinner-border"></span> Cargando
      </div>
    )
  }
  if (error) {
    let err:any = error
    if (err.status === 403) {
      localStorage.setItem('app_token', '')
    }
  }

  return <IndexRoutes />
}

export default App

import { useNavigate, useParams } from "react-router-dom"
import { useCargaDocumentoMutation, useGetDocsListQuery, useGetValidacionMutation, useLazyGetDocsListQuery } from "../../../services/docs"
import { Button, toast } from "@amex/amex-ui-react"
import { ItemEstatusDocumento } from '../../../components/ItemEstatusDocumento'
import { ItemDocumentoCarga } from "../../../components/ItemDocumentoCarga"

export const CargaDocumentosScreen = () => {
  let { siniestro, tramite_id } = useParams()
  const { data, isLoading } = useGetDocsListQuery({ siniestro, tramite: tramite_id })
  const [validarSend, { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset }] = useGetValidacionMutation()
  const navigate = useNavigate();

  const valor: any = []
  if (isLoading) {
    return <h1>Cargando</h1>
  }
  const enviarAValidar= async () => {
    const resp = await validarSend({siniestro,tramite_id: +tramite_id}).unwrap()
    toast({
      description: resp.message,
      title: 'Etapa',
    })
    navigate('/view/siniestro/'+siniestro+'/'+tramite_id);
  }
  return (
    <div className="mx-auto space-y-4 justify-start items-center mb-5">
      <div className="border-solid border-gray-ultra-light flex gap-1 flex-row border-b-2 w-full relative">

        <div className="rounded-t border-solid px-2 flex flex-row gap-4 items-center justify-start h-12 border-b-4 relative overflow-hidden w-full cursor-pointer bg-light border-gray-ultra-light">
          <ItemEstatusDocumento
            color={'bg-gray'}
            label={'Documento No Cargado'}
          />
          <ItemEstatusDocumento
            color={'bg-green-400'}
            label={'Documento Cargado'}
          />
          <ItemEstatusDocumento
            color={'bg-primary'}
            label={'Digital Verificado'}
          />
          <ItemEstatusDocumento
            color={'bg-yellow-400'}
            label={'Digital Rechazado'}
          />
        </div>
      </div>
      {data && data.validacion && <div className="flex justify-center w-full">
        <Button onClick={enviarAValidar}>Enviar a validar</Button>
      </div>
      }
      <h1>Documentos Titular</h1>
      <div className="grid grid-cols-3 gap-4 place-items-stretch">
        {data && data.documentos && data.documentos.map((doc: any, i: number) => {
          valor.push({ imagen: '' })
          return (
            <ItemDocumentoCarga
              key={doc.id}
              valor={valor}
              doc={doc}
              i={i}
              siniestro={siniestro}
              tramite_id={tramite_id}
            />
          )
        })}
      </div>
    </div>
  )
}
